import { Controller } from 'stimulus'
import Floatl from 'floatl'

export default class extends Controller {
  initialize () {
    this.elements = this.element.getElementsByClassName('floatl')
    this.floatls = []
  }

  connect () {
    for (let element of this.elements) {
      this.floatls.push(new Floatl(element))
    }
  }

  disconnect () {
    for (let floatl of this.floatls) {
      floatl.destroy()
    }
  }
}

import { Controller } from 'stimulus'
import fetchJS from 'fetch-js'

const VIATOR_SCRIPT_URL = 'https://www.viator.com/orion/partner/widget.js'

export default class extends Controller {
  connect () {
    this.install()
    
    document.addEventListener('turbolinks:before-cache', this.cleanup)
  }

  disconnect () {
    document.removeEventListener('turbolinks:before-cache', this.cleanup)
  }
  
  install () {
    fetchJS(VIATOR_SCRIPT_URL)
  }
  
  cleanup () {
    const scriptTag = document.querySelector("[data-vi-widget='1']")
   
    scriptTag.remove()
    delete window.__VIATOR_WIDGET_SCR
    
    const controllers = document.querySelectorAll("[data-controller='viator-widget']")
    for (let controller of controllers) {
      controller.replaceChildren();
    }
  }
}

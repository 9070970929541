/* global mapkit */
import { Controller } from 'stimulus'
import fetchJS from 'fetch-js'

const MAPKIT_URL = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js'
const CALLOUT_OFFSET = new DOMPoint(-148, -148)

export default class extends Controller {
  static targets = ['container']

  initialize () {
    const selectedId = this.data.get('selected-id')

    fetchJS(MAPKIT_URL, () => {
      this.initMap()
      this.addAnnotations()
      if (selectedId) this.presentSelectedAnnotation(selectedId)
    })
  }

  presentSelectedAnnotation (selectedId) {
    const annotation = this.map.annotations.find(
      annotation => annotation.spot.id === parseInt(selectedId, 10)
    )

    this.map.showItems(annotation, {
      minimumSpan: new mapkit.CoordinateSpan(0.01, 0.01)
    })
  }

  addAnnotations () {
    const spots = JSON.parse(this.data.get('spots'))
    const annotations = this.annotationsFromSpots(spots)

    this.map.showItems(annotations)
  }

  annotationsFromSpots (spots) {
    return spots.map(spot => {
      const annotation = new mapkit.MarkerAnnotation(
        new mapkit.Coordinate(...spot.coordinate),
        {
          callout: this.spotAnnotationCallout(),
          clusteringIdentifier: 'spot',
          color: spot.color,
          title: spot.name,
          glyphImage: {
            1: spot.glyph_image_1x,
            2: spot.glyph_image_2x,
            3: spot.glyph_image_3x
          }
        }
      )
      annotation.spot = spot
      return annotation
    })
  }

  calloutForSpotAnnotation (annotation) {
    const callout = document.createElement('a')
    callout.className = 'callout'
    callout.href = annotation.spot.path

    // Photo
    const photo = document.createElement('div')
    photo.className = 'photo'

    const img = document.createElement('img')
    img.className = 'callout__img'
    img.src = annotation.spot.image
    photo.appendChild(img)

    const overlay = document.createElement('div')
    overlay.className = 'photo__overlay'
    photo.appendChild(overlay)

    callout.appendChild(photo)

    // Action
    const action = document.createElement('span')
    action.className = 'callout__action'
    action.textContent = annotation.spot.action

    const chevron = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'svg'
    )
    chevron.className.baseVal = 'callout__action-icon'
    chevron.setAttribute('viewBox', '0 0 24 24')
    chevron.setAttribute('width', '24')
    chevron.setAttribute('height', '24')
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path') // Create a path in SVG's namespace
    path.setAttribute('d', 'M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z')
    chevron.appendChild(path)
    action.appendChild(chevron)

    callout.appendChild(action)

    return callout
  }

  initMap () {
    mapkit.init({
      authorizationCallback: done => {
        fetch(this.data.get('remote-token-path'), {
          credentials: 'same-origin'
        })
          .then(res => res.text())
          .then(done)
          .catch(error => {
            console.log(error)
          })
      },
      language: this.data.get('language')
    })

    this.map = new mapkit.Map(this.containerTarget)
    this.map.tintColor = '#4e99c5'
  }

  // Delegate: spot callout

  spotAnnotationCallout () {
    return {
      calloutElementForAnnotation: annotation => {
        return this.calloutForSpotAnnotation(annotation)
      },

      calloutAnchorOffsetForAnnotation: (annotation, element) => {
        return CALLOUT_OFFSET
      },

      calloutAppearanceAnimationForAnnotation: annotation => {
        return 'callout-scale-and-fadein 300ms 0 1 normal cubic-bezier(0.4, 0, 0, 1.5)'
      }
    }
  }
}

import { Controller } from 'stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = ['toggle', 'template']

  connect () {
    this.tippy = tippy(this.toggleTarget, {
      trigger: 'click',
      placement: this.data.get('placement'),
      content: this.content(),
      theme: 'cityspotters',
      arrow: true,
      animation: 'shift-away',
      inertia: true,
      interactive: true,
      onHide: () => {
        this.toggle()
      },
      onShow: () => {
        this.toggle()
      }
    })
  }

  disconnect () {
    this.tippy.destroy()
  }

  toggle () {
    this.toggleTarget.classList.toggle('toggled')
  }

  content () {
    this.templateContainer = document.createElement('div')
    this.templateContainer.appendChild(
      document.importNode(this.templateTarget.content, true)
    )

    return this.templateContainer
  }
}

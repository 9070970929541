import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['container']

  initialize () {
    if (this.hasConsented === false) {
      this.toggleVisibility(true)
    }
  }

  consent () {
    Cookies.set('eu_cookies_consented', true, { expires: 365 })
    this.toggleVisibility(false)
  }

  toggleVisibility (state) {
    this.containerTarget.classList.toggle('cookies-consenter--visible', state)
  }

  get hasConsented () {
    return Cookies.get('eu_cookies_consented') || false
  }
}

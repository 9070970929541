import './ad.css'
import React from 'react'
import PropTypes from 'prop-types'
import { Bling as GPT } from 'react-gpt'

const propTypes = {
  adUnitPath: PropTypes.string.isRequired,
  sizeMapping: PropTypes.arrayOf(
    PropTypes.shape({
      viewport: PropTypes.array.isRequired,
      slot: PropTypes.array.isRequired
    })
  ).isRequired
}

const Ad = props =>
  <GPT adUnitPath={props.adUnitPath} sizeMapping={props.sizeMapping} />

Ad.propTypes = propTypes

export default Ad

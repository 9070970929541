import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect () {
    this.sheetTarget = this.element.closest('.nav-sheet')
  }

  disconnect () {
    this.sheetTarget = null
  }

  toggle (event) {
    if (this.isSheetPresented() || event.type === 'touchstart') {
      event.preventDefault()
    }

    const container = event.target.closest('.city-nav__subnav')
    this.toggleContainer(container)
    this.collapseContainers()
  }

  collapseContainers () {
    for (let container of this.containerTargets) {
      if (!this.isActiveContainer(container)) {
        container.classList.remove('city-nav__subnav--expanded')
      }
    }
  }

  toggleContainer (container) {
    if (container.classList.contains('city-nav__subnav--expanded')) {
      this.data.set('activeIndex', null)
    } else {
      this.data.set('activeIndex', container.dataset.subnavIndex)
    }

    container.classList.toggle('city-nav__subnav--expanded')
  }

  isActiveContainer (container) {
    return (
      parseInt(this.data.get('activeIndex'), 10) ===
      parseInt(container.dataset.subnavIndex, 10)
    )
  }

  isSheetPresented () {
    return this.sheetTarget.classList.contains('nav-sheet--presented')
  }
}

import Cookies from 'js-cookie'
import getUrlParameter from '../utils/getUrlParameter.js'

export default function Affiliates () {
  const ref = getUrlParameter('ref')
  const from = document.referrer
  const time = Date.now() / 1000
  const isSecure = location.protocol === 'https:'

  if (!Cookies.get('aff') && ref) {
    Cookies.set(
      'aff',
      { tag: ref, from: from, time: time },
      { expires: 30, secure: isSecure }
    )
  }
}

import { Controller } from 'stimulus'
import mailcheck from 'mailcheck'

export default class extends Controller {
  static targets = ['message', 'messagePlaceholder']

  initialize () {
    this.input = this.element.querySelectorAll('input')[0]
  }

  connect () {
    this.input.addEventListener('change', () => {
      this.check()
    })
  }

  disconnect () {
    this.input.removeEventListener('change', () => {
      this.check()
    })
  }

  toggleMessage (state) {
    this.messagePlaceholderTarget.innerHTML = this.data.get('suggestion')
    this.element.classList.toggle('erroneous-field', state)
    this.messageTarget.classList.toggle('hidden', !state)
  }

  check () {
    return mailcheck.run({
      email: this.input.value,
      suggested: suggestion => {
        this.data.set('suggestion', suggestion.full)
        this.toggleMessage(true)
      },
      empty: () => {
        this.data.set('suggestion', '')
        this.toggleMessage(false)
      }
    })
  }

  fill () {
    this.input.value = this.data.get('suggestion')
    this.data.set('suggestion', '')
    this.toggleMessage(false)
  }
}

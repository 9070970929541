import { Controller } from 'stimulus'
import fetchJS from 'fetch-js'

const BOOKING_SCRIPT_URL = 'https://aff.bstatic.com/static/affiliate_base/js/flexiproduct.js'

export default class extends Controller {
  connect () {
    fetchJS(`${BOOKING_SCRIPT_URL}?v=${new Date()}`)
  }

  disconnect () {
    delete window.BookingAff
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  hide (e) {
    e.preventDefault()

    this.containerTarget.classList.add('flash--hidden')
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  reload (event) {
    const currency = event.target.getAttribute('data-currency')

    fetch(this.data.get('remotePath'), {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ preferences: { currency: currency } })
    }).then(r => {
      if (r.ok) location.reload()
    })
  }
}

import { Controller } from 'stimulus'
import stickybits from 'stickybits'
import 'intersection-observer'

const NAV_CURRENT_CLASS = 'activity-nav__list-item--current'

export default class extends Controller {
  static targets = [
    'nav',
    'descriptionNav',
    'photosNav',
    'reviewsNav',
    'section',
    'booker',
    'intro',
    'highlights',
    'photos',
    'reviews',
    'bookingForm'
  ]

  initialize () {
    this.bookerObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (
          entry.isIntersecting === false &&
          entry.boundingClientRect.top <= 0
        ) {
          this.toggleNavVisibility(true)
        } else {
          this.toggleNavVisibility(false)
        }
      })
    })

    this.sectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.setCurrent(this.targetForSection(entry.target))
        }
      })
    })
  }

  connect () {
    this.sticky = stickybits('.activity-nav')

    setTimeout(() => {
      if (this.scrolledPastIntro()) this.toggleNavVisibility(true)
    }, 1000)

    if (this.hasBookerTarget) {
      this.bookerObserver.observe(this.bookerTarget)
    } else {
      this.bookerObserver.observe(this.introTarget)
    }

    for (let target of this.sectionTargets) {
      this.sectionObserver.observe(target)
    }
  }

  disconnect () {
    this.sticky.cleanup()

    if (this.hasBookerTarget) {
      this.bookerObserver.unobserve(this.bookerTarget)
    } else {
      this.bookerObserver.unobserve(this.introTarget)
    }

    for (let target of this.sectionTargets) {
      this.sectionObserver.unobserve(target)
    }
  }

  toggleNavVisibility (state) {
    this.navTarget.classList.toggle('activity-nav--presented', state)
  }

  presentIntro () {
    this.introTarget.scrollIntoView({ behavior: 'smooth' })
  }

  presentPhotos () {
    this.photosTarget.scrollIntoView({ behavior: 'smooth' })
  }

  presentReviews () {
    this.reviewsTarget.scrollIntoView({ behavior: 'smooth' })
  }

  presentBookingForm () {
    this.bookingFormTarget.scrollIntoView({ behavior: 'smooth' })
  }

  setCurrent (target) {
    const elem = this[`${target}NavTarget`]

    for (const item of document.getElementsByClassName(NAV_CURRENT_CLASS)) {
      item.classList.remove(NAV_CURRENT_CLASS)
    }

    elem.classList.add(NAV_CURRENT_CLASS)
  }

  targetForSection (section) {
    return section.getAttribute('data-nav-target')
  }

  scrolledPastIntro () {
    const offset = window.pageYOffset || document.documentElement.scrollTop

    return offset - this.highlightsTarget.offsetTop > 0
  }
}

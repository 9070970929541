import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button']

  initialize () {
    this.presentReviews()
  }

  connect () {
    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener('ajax:complete', this.presentReviews)
    }
  }

  presentReviews () {
    const reviews = document.querySelectorAll('.review:not(.review--presented)')

    for (let i = 0; i < reviews.length; i++) {
      setTimeout(() => {
        reviews[i].classList.add('review--presented')
      }, i * 100)
    }
  }

  updateButton () {
    const page = parseInt(this.buttonTarget.href.match(/page=(\d)/)[1], 10)

    this.buttonTarget.setAttribute(
      'href',
      this.buttonTarget.href.replace(`page=${page}`, `page=${page + 1}`)
    )
  }
}

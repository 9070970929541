window.lazySizesConfig = {
  init: false,
  customMedia: {
    '--tiny': '(min-width: 1px)',
    '--tiny--retina':
      '(min-width: 1px) and (-webkit-min-device-pixel-ratio: 2)',
    '--small': '(min-width: 576px)',
    '--small--retina':
      '(min-width: 576px) and (-webkit-min-device-pixel-ratio: 2)',
    '--medium': '(min-width: 768px)',
    '--medium--retina':
      '(min-width: 768px) and (-webkit-min-device-pixel-ratio: 2)',
    '--large': '(min-width: 993px)',
    '--large--retina':
      '(min-width: 993px) and (-webkit-min-device-pixel-ratio: 2)',
    '--huge': '(min-width: 1200px)',
    '--huge--retina':
      '(min-width: 1200px) and (-webkit-min-device-pixel-ratio: 2)'
  }
}

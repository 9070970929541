import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect () {
    const links = this.filterExternalLinks(this.element.querySelectorAll('a'))

    this.decorateLinks(links)
  }

  filterExternalLinks (links) {
    return Array.from(links).filter(link => {
      return link.hostname && link.hostname !== location.hostname
    })
  }

  decorateLinks (links) {
    for (let link of links) {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener')
    }
  }
}

import { Controller } from 'stimulus'
import IntlTelInput from 'intl-tel-input'
import Floatl from 'floatl'

export default class extends Controller {
  static targets = ['input']

  connect () {
    this.form = this.inputTarget.closest('form')

    this.floatl = new Floatl(this.element)
    this.iti = new IntlTelInput(this.inputTarget, {
      preferredCountries: this.data.get('preferredCountries').split(','),
      utilsScript: this.data.get('remoteUtilsScriptPath'),
      nationalMode: true
    })

    this.form.addEventListener('submit', () => {
      this.internationalizeNumber()
    })
  }

  disconnect () {
    this.floatl.destroy()
    this.iti.destroy()

    this.form.removeEventListener('submit', () => {
      this.internationalizeNumber()
    })
  }

  internationalizeNumber (e) {
    if (this.iti.isValidNumber()) {
      this.inputTarget.value = this.iti.getNumber()
    }
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['onLocation', 'online', 'exchangedPaymentNote', 'submit']

  connect () {
    if (this.hasOnlineTarget && this.onlineTarget.checked) {
      this.setOnline()
    } else if (this.hasOnLocationTarget && this.onLocationTarget.checked) {
      this.setOnLocation()
    }
  }

  setOnline () {
    this.toggleSubmitText('online')
    this.toggleExchangedPaymentNote(false)
  }

  setOnLocation () {
    this.toggleSubmitText('onLocation')
    this.toggleExchangedPaymentNote(true)
  }

  toggleExchangedPaymentNote (state) {
    if (this.hasExchangedPaymentNoteTarget) {
      this.exchangedPaymentNoteTarget.classList.toggle('alert--hidden', !state)
    }
  }

  toggleSubmitText (attribute) {
    this.submitTarget.value = this.submitTarget.getAttribute(
      `data-${attribute}text`
    )
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'subjectField',
    'city',
    'cityField',
    'bookable',
    'bookableField',
    'booking'
  ]

  initialize () {
    this.containers = [this.cityTarget, this.bookableTarget, this.bookingTarget]

    this.bookables = Array.from(this.bookableFieldTarget.options)

    this.restoreValues()
  }

  restoreValues () {
    const selectedCityIndex = this.cityFieldTarget.selectedIndex

    if (selectedCityIndex > 0) {
      const cityId = this.cityFieldTarget.options[selectedCityIndex].value
      const selectedBookableIndex = this.bookableFieldTarget.selectedIndex
      let bookableId = null

      if (selectedBookableIndex > 0) {
        bookableId = this.bookableFieldTarget.options[selectedBookableIndex]
          .value
      }

      this.filterActivities(cityId)

      if (bookableId) {
        let selectedIndex = 0

        Array.from(
          this.bookableFieldTarget.options
        ).forEach((option, index) => {
          if (option.value === bookableId) {
            selectedIndex = index
          }
        })

        this.bookableFieldTarget.selectedIndex = selectedIndex
      }
    }
  }

  show (indices) {
    this.containers.forEach((container, index) => {
      container.classList.toggle(
        'form__section--hidden',
        indices.indexOf(index) < 0
      )
    })
  }

  changeSubject (event) {
    const value = event.target.value

    if (value === 'general_question') {
      this.show([])
    } else if (value === 'booking_question') {
      this.show([2])
    } else if (value === 'bookable_question') {
      this.show([0])
    }

    this.cityFieldTarget.selectedIndex = 0
    this.bookableFieldTarget.selectedIndex = 0
  }

  changeCity (event) {
    const value = event.target.value

    if (value) {
      this.filterActivities(value)
      this.show([0, 1])
    } else {
      this.show([0])
    }
  }

  filterActivities (cityId) {
    while (this.bookableFieldTarget.options.length) {
      this.bookableFieldTarget.options.remove(0)
    }

    const options = this.bookables.filter(bookable => {
      const bookableCityId = bookable.getAttribute('data-city-id')

      if (bookableCityId && bookableCityId !== cityId) return false
      return true
    })

    this.bookableFieldTarget.append(...options)
    this.bookableFieldTarget.selectedIndex = 0
  }
}

import { Controller } from 'stimulus'
import mediumZoom from 'medium-zoom'

export default class extends Controller {
  connect () {
    this.zoom = mediumZoom(
      this.element.querySelectorAll('.zoomable-photo-grid__img'),
      {
        margin: 24
      }
    )
  }

  disconnect () {
    this.zoom.detach()
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'container']

  initialize () {
    this.languageElements = this.element.getElementsByClassName(
      'timeslots__language'
    )
  }

  connect () {
    if (this.hasSelectTarget) {
      this.presentLanguage(this.selectTarget.selectedIndex)
    }
  }

  presentLanguage (index) {
    const containerOffset = this.containerTarget.offsetLeft
    const languageOffset = this.languageElements[index].offsetLeft
    const offset = languageOffset - containerOffset

    this.containerTarget.scroll({ top: 0, left: offset, behavior: 'smooth' })
  }

  changeLanguage () {
    this.presentLanguage(this.selectTarget.selectedIndex)
  }
}

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@stimulus/polyfills'
import 'element-remove'
import 'unfetch/polyfill/index'
import 'raf' // React, smoothscroll
import smoothscroll from 'smoothscroll-polyfill'
import templatePolyfill from 'template-polyfill'

smoothscroll.polyfill()
templatePolyfill()

// MapKit
window.DOMPoint = class DOMPoint {
  constructor (x = 0, y = 0, z = 0, w = 1) {
    this.x = x
    this.y = y
    this.z = z
    this.w = w
  }
}

import { Controller } from 'stimulus'
import stickybits from 'stickybits'
import throttle from 'lodash.throttle'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default class extends Controller {
  static targets = ['toggle', 'container']

  connect () {
    this.toggleSticky()

    window.addEventListener(
      'resize',
      throttle(() => {
        this.toggleSticky()
        if (this.isPresented()) this.toggle()
      }, 100)
    )
  }

  disconnect () {
    if (this.sticky) this.sticky.cleanup()
    enableBodyScroll(this.containerTarget)

    window.removeEventListener(
      'resize',
      throttle(() => {
        this.toggleSticky()
        if (this.isPresented()) this.toggle()
      }, 100)
    )
  }

  toggle () {
    this.element.classList.toggle('nav-sheet--presented')
    this.toggleTarget.classList.toggle('tcon-transform')

    if (this.isPresented()) {
      this.present()
    } else {
      this.dismiss()
    }
  }

  toggleSticky () {
    if (window.matchMedia) {
      if (window.matchMedia('(min-width: 768px)').matches) {
        this.sticky = stickybits('.nav-sheet')
      } else if (this.sticky) {
        this.sticky.cleanup()
        this.element.style = ''
      }
    }
  }

  isPresented () {
    return this.element.classList.contains('nav-sheet--presented')
  }

  presentSheet () {
    this.element.style.transform = `translateY(-${window.innerHeight}px)`
  }

  present () {
    disableBodyScroll(this.containerTarget)
    this.presentSheet()
  }

  dismiss () {
    enableBodyScroll(this.containerTarget)
    this.element.style.transform = ''
  }
}

import { Controller } from 'stimulus'
import fetchJS from 'fetch-js'

const GYG_SCRIPT_URL = 'https://widget.getyourguide.com/dist/pa.umd.production.min.js'
const GYG_PARTNER_ID = 'H5KUQGV'

export default class extends Controller {
  connect () {
    this.install()
  }
  
  install () {
    const scriptTag = document.head.querySelector(`script[data-gyg-partner-id="${GYG_PARTNER_ID}"]`)
    
    if (!scriptTag) {
      const element = document.createElement('script')
      element.async = true
      element.dataset.gygPartnerId = GYG_PARTNER_ID
      element.defer = true
      element.src = GYG_SCRIPT_URL
      
      document.head.appendChild(element)
    }
  }
}

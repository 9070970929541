import { Controller } from 'stimulus'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default class extends Controller {
  static targets = ['modal']

  present () {
    this.modalTarget.classList.add('modal--presented')
    disableBodyScroll(this.modalTarget)

    document.addEventListener('keyup', event => {
      if (event.keyCode === 27) this.dismiss()
    })
  }

  dismiss () {
    this.modalTarget.classList.remove('modal--presented')
    enableBodyScroll(this.modalTarget)

    document.removeEventListener('keyup', event => {
      if (event.keyCode === 27) this.dismiss()
    })
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.element.addEventListener('submit', event => {
      this.onSubmit(event)
    })
  }

  onSubmit (event) {
    event.preventDefault()

    this.fetchToken().then(token => {
      const input = this.element.querySelectorAll(
        'input[name="authenticity_token"]'
      )[0]

      if (input) input.value = token

      this.element.removeEventListener('submit', event => {
        this.onSubmit(event)
      })
      this.element.submit()
    })
  }

  fetchToken () {
    return fetch('/token', {
      credentials: 'same-origin'
    }).then(response => response.text())
  }
}

import Parallax from 'scroll-parallax/dist/Parallax'

let parallaxes = []

function instantiateLazyParallax (e) {
  const element = e.target
  const selector = element.getAttribute('data-parallax-class')

  if (selector) {
    parallaxes = [
      ...parallaxes,
      new Parallax(`.${selector}`, {
        intensity: 24,
        center: 0.5,
        safeHeight: 0.2
      }).init()
    ]
  }
}

function destroyParallaxes () {
  parallaxes.forEach(parallax => {
    parallax.destroy()
  })
}

document.addEventListener('lazybeforeunveil', instantiateLazyParallax)
document.addEventListener('turbolinks:before-cache', destroyParallaxes)

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['flashes']

  initialize () {
    const container = document.getElementById('js-flashes')
    const flashesEl = this.flashesTarget

    container.classList.add('flashes--presented')

    if (container) {
      container.insertAdjacentHTML('afterbegin', flashesEl.innerHTML)
      flashesEl.parentNode.removeChild(flashesEl)
    }
  }
}

import '../polyfills.js'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Turbolinks from 'turbolinks'
import Rails from 'rails-ujs'

// Imports
import 'ahoy.js'
import '../services.js'
import '../modules/config.js'
import '../modules/hooks.js'
import '../modules/lazySizes.js'
import '../modules/parallaxes.js'
import '../modules/webfonts.js'

// Components
import '../init/init.js'

// Configure Stimulus, Rails, Turbolinks
const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
Rails.start()
Turbolinks.start()

// Configure React
var ReactRailsUJS = require('react_ujs')
var componentRequireContext = require.context('components', true)
ReactRailsUJS.useContext(componentRequireContext)

import './search-bar.css'
import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'
import AutosuggestHighlightParse from 'autosuggest-highlight/parse'
import reactMixin from 'react-mixin'
import LocalStorageMixin from 'react-localstorage'
import Fuse from 'fuse.js'
import cn from 'classnames'
import Turbolinks from 'turbolinks'

const propTypes = {
  locale: PropTypes.string.isRequired,
  remoteSourcePath: PropTypes.string.isRequired,
  remoteSubmitPath: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired
}

const defaultProps = {
  stateFilterKeys: ['localizedData']
}

function highlight (text, query) {
  const matches = AutosuggestHighlightMatch(text, query.trim())
  const parts = AutosuggestHighlightParse(text, matches)

  return parts.map(part => {
    const className = part.highlight ? 'highlight' : null

    return (
      <span className={className} key={part.text}>
        {part.text}
      </span>
    )
  })
}

function shouldRenderSuggestions (value) {
  return typeof value !== 'undefined' && value.trim().length > 1
}

const getSectionSuggestions = section => section.suggestions
const getSuggestionValue = suggestion => suggestion.name
const renderSectionTitle = section => null

const renderSuggestion = (suggestion, { query }) => (
  <div className='react-autosuggest__container'>
    <div className='react-autosuggest__thumbnail'>
      <div className='lazy-photo lazy-photo--shaded lazy-photo--linked'>
        <img
          className='lazy-photo__photo react-autosuggest__img lazyload'
          width='50'
          height='50'
          data-src={suggestion.thumbnail}
        />
        <div className='lazy-photo__overlay' />
      </div>
    </div>
    <div className='react-autosuggest__content'>
      <div className='react-autosuggest__title'>
        {highlight(suggestion.title, query)}
      </div>
      {suggestion.subtitle && (
        <div className='react-autosuggest__subtitle'>
          {highlight(suggestion.subtitle, query)}
        </div>
      )}
    </div>
  </div>
)

class SearchBar extends React.Component {
  constructor () {
    super()

    this.state = {
      localizedData: {},
      suggestions: [],
      value: '',
      didUpdateData: false,
      isLoading: false,
      isActive: false
    }

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    )
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    )
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    this.onIconClick = this.onIconClick.bind(this)
  }

  onFocus () {
    if (!this.state.didUpdateData) this.getData()

    this.setState({
      didUpdateData: true,
      isActive: true
    })
  }

  onBlur () {
    this.setState({
      isActive: false
    })
  }

  onChange (event, { newValue }) {
    if (typeof newValue === 'undefined') return

    this.setState({
      value: newValue
    })
  }

  onSuggestionsFetchRequested (value) {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  }

  onSuggestionsClearRequested () {
    this.setState({
      suggestions: []
    })
  }

  onSuggestionSelected (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    const { localizedData, value } = this.state
    const type = localizedData[this.props.locale][sectionIndex].type
    const id = suggestion.id

    this.setState(
      {
        isLoading: true
      },
      () => {
        fetch(
          `${
            this.props.remoteSubmitPath
          }?search[type]=${type}&search[id]=${id}&search[query]=${value}`,
          { method: 'POST', credentials: 'same-origin' }
        )
          .then(response => response.text())
          .then(response => {
            this.setState({ isLoading: false })
            if (response) Turbolinks.visit(response)
          })
      }
    )
  }

  onIconClick () {
    this.inputElement.focus()
    this.inputElement.setSelectionRange(0, this.inputElement.value.length)
  }

  getData () {
    this.setState(
      {
        isLoading: true
      },
      () => {
        fetch(this.props.remoteSourcePath)
          .then(response => response.json())
          .then(response => {
            this.setState({
              localizedData: Object.assign(this.state.localizedData, {
                [this.props.locale]: response
              }),
              isLoading: false
            })

            this.focus()
          })
      }
    )
  }

  getSuggestions (value) {
    const options = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: [
        {
          name: 'title',
          weight: 0.7
        },
        {
          name: 'subtitle',
          weight: 0.3
        }
      ]
    }

    return this.state.localizedData[this.props.locale].map(section => {
      const fuse = new Fuse(section.suggestions, options)

      return {
        title: section.title,
        suggestions: fuse.search(value.value).slice(0, 5)
      }
    })
  }

  focus () {
    this.inputElement.blur()
    this.inputElement.focus()
  }

  render () {
    const { value, suggestions } = this.state
    const inputProps = {
      placeholder: this.props.placeholderText,
      autoCorrect: 'off',
      spellCheck: 'false',
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onChange: this.onChange,
      value
    }

    return (
      <div
        className={cn({
          'search-bar': true,
          'search-bar--active': this.state.isActive
        })}
      >
        <Autosuggest
          ref={autosuggest => {
            if (autosuggest && autosuggest.input) {
              this.inputElement = autosuggest.input
            }
          }}
          multiSection
          suggestions={suggestions}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          getSectionSuggestions={getSectionSuggestions}
          renderSectionTitle={renderSectionTitle}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        {!this.state.isLoading && (
          <svg
            onClick={this.onIconClick}
            className='search-bar__icon'
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
          >
            <path d='M18.869 19.162l-5.943-6.484c1.339-1.401 2.075-3.233 2.075-5.178 0-2.003-.78-3.887-2.197-5.303S9.504 0 7.501 0 3.614.78 2.198 2.197.001 5.497.001 7.5s.78 3.887 2.197 5.303S5.498 15 7.501 15c1.726 0 3.362-.579 4.688-1.645l5.943 6.483c.099.108.233.162.369.162.121 0 .242-.043.338-.131.204-.187.217-.503.031-.706zM1 7.5C1 3.916 3.916 1 7.5 1S14 3.916 14 7.5 11.084 14 7.5 14 1 11.084 1 7.5z' />
          </svg>
        )}
        {this.state.isLoading && <div className='search-bar__spinner' />}
      </div>
    )
  }
}

SearchBar.propTypes = propTypes
SearchBar.defaultProps = defaultProps

reactMixin.onClass(SearchBar, LocalStorageMixin)

export default SearchBar

import { Controller } from 'stimulus'

export default class extends Controller {
  success (event) {
    this.element.classList.add('newsletter-form--complete')
  }

  error () {
    this.element.classList.add('newsletter-form--erroneous')
  }
}

import './bookings-online-payment-method.css'
import './static/amex.svg'
import './static/bancontact.svg'
import './static/belfius.svg'
import './static/ideal.svg'
import './static/kbc.svg'
import './static/maestro.svg'
import './static/mastercard.svg'
import './static/paypal.svg'
import './static/visa.svg'
import './static/vpay.svg'
